import { useState, useEffect, useContext } from "react";
import { addDescripcionToAulas } from "../Services/FormService";
import { fetchAulasByEstado } from "../Services/GeneracionLinkService";
import { parseArrayToReactSelect } from "../Services/UltilsService";

const useAulas = (idServicio, conDescripcion = false, grupo) => {
  const [aulas, setAulas] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  function restarDias(fecha, dias){
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  useEffect(() => {
    if (!idServicio) return;

    (async () => {
      setIsFetching(true);
      setAulas(await getAulas(idServicio));
      setIsFetching(false);
    })();
  }, [idServicio]);

  const getAulas = async () => {

    const porIniciar = await fetchAulasByEstado(idServicio, "por_iniciar");
    const enCurso = await fetchAulasByEstado(idServicio, "en_curso");
    const porIniciarFilter = [];
    const enCursoFilter = [];

    if(grupo == 1 || grupo == 11)
    {
      const AulaEnEsperaCar = parseArrayToReactSelect(
        [{ id: 19, alias: "AULA EN ESPERA (C)" }],
        "id",
        "alias"
      );
  
      if(idServicio == 3 || idServicio == 10 || idServicio == 209)
      {
        const fechaActual = new Date();
        const fechaActual30Dias = restarDias(fechaActual, -30);
        const [mes30D, dia30D, año30D] = [
          fechaActual30Dias.getMonth() + 1,
          fechaActual30Dias.getDate(),
          fechaActual30Dias.getFullYear(),
        ];
        
        enCurso.forEach(item => {
          
          let [mes, dia, año] = [
            item.tiempoInicio.slice(3).slice(0,2),
            item.tiempoInicio.slice(0,2),
            item.tiempoInicio.slice(-4),
          ];
  
          if(
            año > año30D ||
            (año == año30D && mes > mes30D) ||
            (año == año30D && mes == mes30D && dia >= dia30D)
          )
          {
            enCursoFilter.push(item);
          }
        });
        //return await (addDescripcionToAulas(porIniciar.concat(enCursoFilter)));
        return await (AulaEnEsperaCar).concat(addDescripcionToAulas(porIniciar.concat(enCursoFilter)));
      }
      return await (AulaEnEsperaCar);
    }

    if(grupo == 5){
      porIniciar.forEach(item => {
        if(item && item.posgradoMax == false)
        {
          porIniciarFilter.push(item);
        }
      });
  
      const AulaEnEsperaPos = parseArrayToReactSelect(
        [{ id: 18, alias: "AULA EN ESPERA (P)" }],
        "id",
        "alias"
      );
      return await AulaEnEsperaPos.concat(addDescripcionToAulas(porIniciarFilter));
    }

    if (!conDescripcion) {
      return await parseArrayToReactSelect(
        porIniciar.concat(enCurso),
        "id",
        "alias"
      );
    }

    return await addDescripcionToAulas(porIniciar.concat(enCurso));
  };

  return { aulas, isFetching };
};

export default useAulas;
